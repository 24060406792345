import { useState } from 'react'
import {
  ipdButtons,
  ipdPatients,
  reasonOptionsArray,
  selectDate,
  selectReason,
  stateValues,
  toastSuccessMessages,
} from '../constants'
import { Button, useTheme } from '@mui/material'
import { emptyString, noData } from '../../Radiology/Constants/constants'
import {
  allocateApi,
  allocation,
  errorMessages,
  tableHeadText,
} from '../modals'
import KeycloakService from '../../../utils/keycloakService'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { toast } from 'react-toastify'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { readableDateFormat } from '../../../utils/dateUtils'

type Props = {
  onConfirm: (image: boolean) => void
  onClose: () => void
  dischargeDetails: ipdPatients | null
  setUpdatedCount?: any
}
const styles = () => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bedContent: {
    marginBottom: '10px',
  },
  selectDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectDateContent: {
    display: 'flex',
    gap: 10,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '20px',
  },
  buttonContent: {
    fontSize: '10px',
    textTransform: 'capitalize',
  },
  reasonContent: {
    fontSize: '13px',
  },
})
const AddDischargeDetails = ({
  onConfirm,
  onClose,
  dischargeDetails,
  setUpdatedCount,
}: Props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [selectedDate, setSelectedDate] = useState('')
  const [reason, setReason] = useState('')
  const [error, setError] = useState('')
  const admissionDate = dischargeDetails?.dateStarted
    ? new Date(dischargeDetails?.dateStarted)?.toISOString()?.split('T')?.[0]
    : emptyString
  const handleDateChange = (event: any) => {
    const selectedDateValue = event?.target?.value
    const currentDate = new Date()?.toISOString()?.split('T')?.[0]
    if (selectedDateValue > currentDate) {
      setError(errorMessages?.selectUpcomingDate)
    } else {
      setError('')
      setSelectedDate(selectedDateValue)
    }
  }
  const handleDischargeDate = () => {
    const state = {
      id: dischargeDetails?.bedId,
      patientId: emptyString,
      bedId: dischargeDetails?.bedId,
      bedMapId: dischargeDetails?.id,
      patientMapId: dischargeDetails?.patientId,
      bedStatus: allocation?.dischargedText,
      dateOfDischarge: selectedDate,
      updatedBy: KeycloakService?.getUsername(),
      patientName: emptyString,
      admissionDate: emptyString,
      dischargedDate: emptyString,
      status: allocation?.available,
      patientFhirId: dischargeDetails?.patient_registered_id,
      dateCreated: new Date()?.toISOString(),
      reason: reason,
    }
    startSxpProxy(
      IPD_PROJECT_ID,
      allocateApi?.newDischargePatientJourney,
      state
    )
      .then((data) => {
        toast?.success(toastSuccessMessages?.dischargedSuccessfully)
        const encounterId = data?.data
        const state = {
          status: stateValues?.finished,
          encounterId: encounterId,
          reason: stateValues?.discharged,
        }
        startSxpProxy(IPD_PROJECT_ID, allocateApi?.closeEncounter, state)
          .then(() => {
            toast.success(toastSuccessMessages?.encounterClosedSuccessfully)
            setUpdatedCount((prevState: any) => prevState + 1)
            onConfirm(false)
          })
          .catch(() => {
            toast.error(toastSuccessMessages?.encounterFailure)
          })
      })
      .catch((err) => {
        console.log(error)
        err?.response?.data?.error?.errors?.forEach((message: string) => {
          toast?.error(message)
        })
        toast?.error(err?.response?.data?.message)
      })
  }
  return (
    <>
      <div className={classes?.mainContainer}>
        <table className='bed-table'>
          <thead>
            <tr>
              <th>{tableHeadText?.bedNo}</th>
              <th className='text-left'>{tableHeadText?.location}</th>
              <th className='text-center'>{tableHeadText?.admission}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-center'>{dischargeDetails?.bedNumber}</td>
              <td className='text-center'>{dischargeDetails?.location}</td>
              <td className='text-center'>
                {dischargeDetails?.dateCreated
                  ? readableDateFormat(
                      new Date(dischargeDetails?.dateCreated ?? '')
                    )
                  : noData}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes?.bedContent}>
        <div>
          <span className='bed-font'>{allocation?.patientDetails}</span>
        </div>
        <table className='bed-table'>
          <thead>
            <tr>
              <th>{tableHeadText?.uhid}</th>
              <th className='text-center'>{tableHeadText?.name}</th>
              <th className='text-center'>{tableHeadText?.age}</th>
              <th className='text-center'>{tableHeadText?.gender}</th>
              <th className='text-center w22'>{tableHeadText?.doctorName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='text-center'>{dischargeDetails?.patientId}</td>
              <td className='text-center'>{dischargeDetails?.patientName}</td>
              <td className='text-center'>{dischargeDetails?.patientAge}</td>
              <td className='text-center'>{dischargeDetails?.patientGender}</td>
              <td className='text-center'>
                {dischargeDetails?.assigned_doctor}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes?.selectDateContainer}>
        <div className={classes?.selectDateContent}>
          <div>
            <span className={classes?.reasonContent}>{selectDate}</span>
            <input
              type='date'
              value={selectedDate}
              min={admissionDate}
              max={new Date()?.toISOString()?.split('T')?.[0]}
              onChange={handleDateChange}
              placeholder='dd/mm/yyyy'
            />
            {error && <span>{error}</span>}
          </div>
          <div>
            <span className={classes?.reasonContent}>{selectReason}</span>
            <select
              value={reason}
              onChange={(e) => setReason(e?.target?.value)}
            >
              {reasonOptionsArray?.map((option, index) => (
                <option key={index} value={option?.value}>
                  {option?.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={classes?.buttonContainer}>
          <Button
            variant='contained'
            disabled={!selectedDate || !reason}
            onClick={handleDischargeDate}
            className={classes?.buttonContent}
          >
            {ipdButtons?.confirm}
          </Button>
          <Button
            variant='contained'
            onClick={onClose}
            className={classes?.buttonContent}
          >
            {ipdButtons?.cancel}
          </Button>
        </div>
      </div>
    </>
  )
}
export default AddDischargeDetails
